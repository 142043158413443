// ActionPlansTable.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeActionPlan } from '../../../slices/thunks';
import { Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';

const ActionPlansTable = ({ actionPlans, afterRemoval = null }) => {
    const dispatch = useDispatch();

    // State for modal confirmation
    const [selectedActionPlan, setSelectedActionPlan] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);

    // Function to open the confirmation modal
    const handleRemoveActionPlan = (actionPlan) => {
        setSelectedActionPlan(actionPlan);
        setConfirmModal(true);
    };

    // Function to confirm and remove the action plan
    const confirmRemoveActionPlan = () => {
        if (selectedActionPlan) {
            dispatch(removeActionPlan({ actionPlanId: selectedActionPlan.ActionPlanId }))
                .then(() => {
                    console.log(`Action plan ${selectedActionPlan.ActionPlanId} removed successfully`);
                })
                .catch((error) => {
                    console.error('Error removing action plan:', error);
                })
                .finally(() => {
                    setConfirmModal(false);
                    setSelectedActionPlan(null);
                    if (afterRemoval) {
                        afterRemoval();
                    }
                });
        }
    };

    return (
        <React.Fragment>
            <Table striped responsive>
                <thead>
                    <tr>
                        <th>Action Plan ID</th>
                        <th>Next Execution Time</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {actionPlans.map((plan, index) => (
                        <tr key={index}>
                            <td>{plan.ActionPlanId}</td>
                            <td>{plan.custom_NextExecTime_hr}</td>
                            <td>
                                <Button color="danger" onClick={() => handleRemoveActionPlan(plan)}>
                                    Remove Action Plan
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Confirmation Modal */}
            <Modal isOpen={confirmModal} toggle={() => setConfirmModal(!confirmModal)} centered>
                <ModalHeader toggle={() => setConfirmModal(!confirmModal)}>
                    Confirm Remove Action Plan
                </ModalHeader>
                <ModalBody>
                    {`Are you sure you want to remove ${selectedActionPlan?.ActionPlanId}?`}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={confirmRemoveActionPlan}>Confirm</Button>
                    <Button color="secondary" onClick={() => setConfirmModal(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default ActionPlansTable;
