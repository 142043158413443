import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = data => api.create(url.POST_FAKE_REGISTER, data);

// Login Method
export const postFakeLogin = data => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = data => api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = data => api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data) => api.update(url.POST_EDIT_PROFILE + '/' + data.idx, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data)
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message = "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
export const postJwtLogin = data => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = data => api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => api.create(url.SOCIAL_LOGIN, data);

// // Calendar
// // get Events
// export const getEvents = () => api.get(url.GET_EVENTS);

// // get Events
// export const getCategories = () => api.get(url.GET_CATEGORIES);

// // get Upcomming Events
// export const getUpCommingEvent = () => api.get(url.GET_UPCOMMINGEVENT);

// // add Events
// export const addNewEvent = event => api.create(url.ADD_NEW_EVENT, event);

// // update Event
// export const updateEvent = event => api.put(url.UPDATE_EVENT, event);

// // delete Event
// export const deleteEvent = event => api.delete(url.DELETE_EVENT, { headers: { event } });

// // Chat
// // get Contact
// export const getDirectContact = () => api.get(url.GET_DIRECT_CONTACT);

// // get Messages
// export const getMessages = roomId => api.get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// // add Message
// export const addMessage = message => api.create(url.ADD_MESSAGE, message);

// // add Message
// export const deleteMessage = message => api.delete(url.DELETE_MESSAGE, { headers: { message } });

// // get Channels
// export const getChannels = () => api.get(url.GET_CHANNELS);

// // MailBox
// //get Mail
// export const getMailDetails = () => api.get(url.GET_MAIL_DETAILS);

// // delete Mail
// export const deleteMail = forId => api.delete(url.DELETE_MAIL, { headers: { forId } });

// // Ecommerce
// // get Products
// export const getProducts = () => api.get(url.GET_PRODUCTS);

// // delete Product
// export const deleteProducts = product => api.delete(url.DELETE_PRODUCT + '/' + product);

// // add Products
// export const addNewProduct = product => api.create(url.ADD_NEW_PRODUCT, product);

// // update Products
// export const updateProduct = product => api.update(url.UPDATE_PRODUCT + '/' + product._id, product);

// // get Orders
// export const getOrders = () => api.get(url.GET_ORDERS);

// // add Order
// export const addNewOrder = order => api.create(url.ADD_NEW_ORDER, order);

// // update Order
// export const updateOrder = order => api.update(url.UPDATE_ORDER + '/' + order._id, order);

// // delete Order
// export const deleteOrder = order => api.delete(url.DELETE_ORDER + '/' + order);

// // get Customers
// export const getCustomers = () => api.get(url.GET_CUSTOMERS);

// // add Customers
// export const addNewCustomer = customer => api.create(url.ADD_NEW_CUSTOMER, customer);

// // update Customers
// export const updateCustomer = customer => api.update(url.UPDATE_CUSTOMER + '/' + customer._id, customer);

// // delete Customers
// export const deleteCustomer = customer => api.delete(url.DELETE_CUSTOMER + '/' + customer);

// // get Sellers
// export const getSellers = () => api.get(url.GET_SELLERS);

// // Project
// // get Project list 
// export const getProjectList = () => api.get(url.GET_PROJECT_LIST);

// // Tasks
// // get Task
// export const getTaskList = () => api.get(url.GET_TASK_LIST);

// // add Task
// export const addNewTask = task => api.create(url.ADD_NEW_TASK, task);

// // update Task
// export const updateTask = task => api.update(url.UPDATE_TASK + '/' + task._id, task);

// // delete Task
// export const deleteTask = task => api.delete(url.DELETE_TASK + '/' + task);

// // Kanban Board
// export const getTasks = () => api.get(url.GET_TASKS);
// export const addNewTasks = (card) => api.create(url.ADD_TASKS, card)
// export const updateTasks = (card) => api.put(url.UPDATE_TASKS, card)
// export const deleteTasks = (card) => api.delete(url.DELETE_TASKS, { headers: {card} })

// // CRM
// // get Contacts
// export const getContacts = () => api.get(url.GET_CONTACTS);

// // add Contact
// export const addNewContact = contact => api.create(url.ADD_NEW_CONTACT, contact);

// // update Contact
// export const updateContact = contact => api.update(url.UPDATE_CONTACT + '/' + contact._id, contact);

// // delete Contact
// export const deleteContact = contact => api.delete(url.DELETE_CONTACT + '/' + contact);

// // get Companies
// export const getCompanies = () => api.get(url.GET_COMPANIES);

// // add Companies
// export const addNewCompanies = company => api.create(url.ADD_NEW_COMPANIES, company);

// // update Companies
// export const updateCompanies = company => api.update(url.UPDATE_COMPANIES + '/' + company._id, company);

// // delete Companies
// export const deleteCompanies = company => api.delete(url.DELETE_COMPANIES + '/' + company);

// // get Deals
// export const getDeals = () => api.get(url.GET_DEALS);

// // get Leads
// export const getLeads = () => api.get(url.GET_LEADS);

// // add Lead
// export const addNewLead = lead => api.create(url.ADD_NEW_LEAD, lead);

// // update Lead
// export const updateLead = lead => api.update(url.UPDATE_LEAD + '/' + lead._id, lead);

// // delete Lead
// export const deleteLead = lead => api.delete(url.DELETE_LEAD + '/' + lead);

// // Crypto
// // Transation
// export const getTransationList = () => api.get(url.GET_TRANSACTION_LIST);

// // Order List
// export const getOrderList = () => api.get(url.GET_ORDRER_LIST);

// Invoice
//get Invoice
// export const getInvoices = () => api.get(url.GET_INVOICES);

// // add Invoice
// export const addNewInvoice = invoice => api.create(url.ADD_NEW_INVOICE, invoice);

// // update Invoice
// export const updateInvoice = invoice => api.update(url.UPDATE_INVOICE + '/' + invoice._id, invoice);

// // delete Invoice
// export const deleteInvoice = invoice => api.delete(url.DELETE_INVOICE + '/' + invoice);

// Support Tickets 
// Tickets
export const getTicketsList = () => api.get(url.GET_TICKETS_LIST);

// add Tickets 
export const addNewTicket = ticket => api.create(url.ADD_NEW_TICKET, ticket);

// update Tickets 
export const updateTicket = ticket => api.update(url.UPDATE_TICKET + '/' + ticket._id, ticket);

// delete Tickets 
export const deleteTicket = ticket => api.delete(url.DELETE_TICKET + '/' + ticket);

// // Dashboard Analytics

// // Sessions by Countries
// export const getAllData = () => api.get(url.GET_ALL_DATA);
// export const getHalfYearlyData = () => api.get(url.GET_HALFYEARLY_DATA);
// export const getMonthlyData = () => api.get(url.GET_MONTHLY_DATA);

// // Audiences Metrics
// export const getAllAudiencesMetricsData = () => api.get(url.GET_ALLAUDIENCESMETRICS_DATA);
// export const getMonthlyAudiencesMetricsData = () => api.get(url.GET_MONTHLYAUDIENCESMETRICS_DATA);
// export const getHalfYearlyAudiencesMetricsData = () => api.get(url.GET_HALFYEARLYAUDIENCESMETRICS_DATA);
// export const getYearlyAudiencesMetricsData = () => api.get(url.GET_YEARLYAUDIENCESMETRICS_DATA);

// // Users by Device
// export const getTodayDeviceData = () => api.get(url.GET_TODAYDEVICE_DATA);
// export const getLastWeekDeviceData = () => api.get(url.GET_LASTWEEKDEVICE_DATA);
// export const getLastMonthDeviceData = () => api.get(url.GET_LASTMONTHDEVICE_DATA);
// export const getCurrentYearDeviceData = () => api.get(url.GET_CURRENTYEARDEVICE_DATA);

// // Audiences Sessions by Country
// export const getTodaySessionData = () => api.get(url.GET_TODAYSESSION_DATA);
// export const getLastWeekSessionData = () => api.get(url.GET_LASTWEEKSESSION_DATA);
// export const getLastMonthSessionData = () => api.get(url.GET_LASTMONTHSESSION_DATA);
// export const getCurrentYearSessionData = () => api.get(url.GET_CURRENTYEARSESSION_DATA);

// // Dashboard CRM

// // Balance Overview
// export const getTodayBalanceData = () => api.get(url.GET_TODAYBALANCE_DATA);
// export const getLastWeekBalanceData = () => api.get(url.GET_LASTWEEKBALANCE_DATA);
// export const getLastMonthBalanceData = () => api.get(url.GET_LASTMONTHBALANCE_DATA);
// export const getCurrentYearBalanceData = () => api.get(url.GET_CURRENTYEARBALANCE_DATA);

// // Dial Type
// export const getTodayDealData = () => api.get(url.GET_TODAYDEAL_DATA);
// export const getWeeklyDealData = () => api.get(url.GET_WEEKLYDEAL_DATA);
// export const getMonthlyDealData = () => api.get(url.GET_MONTHLYDEAL_DATA);
// export const getYearlyDealData = () => api.get(url.GET_YEARLYDEAL_DATA);

// // Sales Forecast
// export const getOctSalesData = () => api.get(url.GET_OCTSALES_DATA);
// export const getNovSalesData = () => api.get(url.GET_NOVSALES_DATA);
// export const getDecSalesData = () => api.get(url.GET_DECSALES_DATA);
// export const getJanSalesData = () => api.get(url.GET_JANSALES_DATA);

// Dashboard Ecommerce
// Revenue
export const getAllRevenueData = () => api.get(url.GET_ALLREVENUE_DATA);
export const getMonthRevenueData = () => api.get(url.GET_MONTHREVENUE_DATA);
export const getHalfYearRevenueData = () => api.get(url.GET_HALFYEARREVENUE_DATA);
export const getYearRevenueData = () => api.get(url.GET_YEARREVENUE_DATA);


// // Dashboard Crypto
// // Portfolio
// export const getBtcPortfolioData = () => api.get(url.GET_BTCPORTFOLIO_DATA);
// export const getUsdPortfolioData = () => api.get(url.GET_USDPORTFOLIO_DATA);
// export const getEuroPortfolioData = () => api.get(url.GET_EUROPORTFOLIO_DATA);

// // Market Graph
// export const getAllMarketData = () => api.get(url.GET_ALLMARKETDATA_DATA);
// export const getYearMarketData = () => api.get(url.GET_YEARMARKET_DATA);
// export const getMonthMarketData = () => api.get(url.GET_MONTHMARKET_DATA);
// export const getWeekMarketData = () => api.get(url.GET_WEEKMARKET_DATA);
// export const getHourMarketData = () => api.get(url.GET_HOURMARKET_DATA);

// // Dashboard Project
// // Project Overview
// export const getAllProjectData = () => api.get(url.GET_ALLPROJECT_DATA);
// export const getMonthProjectData = () => api.get(url.GET_MONTHPROJECT_DATA);
// export const gethalfYearProjectData = () => api.get(url.GET_HALFYEARPROJECT_DATA);
// export const getYearProjectData = () => api.get(url.GET_YEARPROJECT_DATA);

// // Project Status
// export const getAllProjectStatusData = () => api.get(url.GET_ALLPROJECTSTATUS_DATA);
// export const getWeekProjectStatusData = () => api.get(url.GET_WEEKPROJECTSTATUS_DATA);
// export const getMonthProjectStatusData = () => api.get(url.GET_MONTHPROJECTSTATUS_DATA);
// export const getQuarterProjectStatusData = () => api.get(url.GET_QUARTERPROJECTSTATUS_DATA);

// // Dashboard NFT
// // Marketplace
// export const getAllMarketplaceData = () => api.get(url.GET_ALLMARKETPLACE_DATA);
// export const getMonthMarketplaceData = () => api.get(url.GET_MONTHMARKETPLACE_DATA);
// export const gethalfYearMarketplaceData = () => api.get(url.GET_HALFYEARMARKETPLACE_DATA);
// export const getYearMarketplaceData = () => api.get(url.GET_YEARMARKETPLACE_DATA);

// // Project
// export const addProjectList = (project) => api.create(url.ADD_NEW_PROJECT, project);
// export const updateProjectList = (project) => api.put(url.UPDATE_PROJECT, project);
// export const deleteProjectList = (project) => api.delete(url.DELETE_PROJECT, { headers: { project } });

// // Pages > Team
// export const getTeamData = (team) => api.get(url.GET_TEAMDATA, team);
// export const deleteTeamData = (team) => api.delete(url.DELETE_TEAMDATA, { headers: { team } });
// export const addTeamData = (team) => api.create(url.ADD_NEW_TEAMDATA, team);
// export const updateTeamData = (team) => api.put(url.UPDATE_TEAMDATA, team);

// // File Manager

// // Folder
// export const getFolders = (folder) => api.get(url.GET_FOLDERS, folder);
// export const deleteFolder = (folder) => api.delete(url.DELETE_FOLDER, { headers: { folder } });
// export const addNewFolder = (folder) => api.create(url.ADD_NEW_FOLDER, folder);
// export const updateFolder = (folder) => api.put(url.UPDATE_FOLDER, folder);

// // File
// export const getFiles = (file) => api.get(url.GET_FILES, file);
// export const deleteFile = (file) => api.delete(url.DELETE_FILE, { headers: { file } });
// export const addNewFile = (file) => api.create(url.ADD_NEW_FILE, file);
// export const updateFile = (file) => api.put(url.UPDATE_FILE, file);

// // To Do
// export const getTodos = (todo) => api.get(url.GET_TODOS, todo);
// export const deleteTodo = (todo) => api.delete(url.DELETE_TODO, { headers: { todo } });
// export const addNewTodo = (todo) => api.create(url.ADD_NEW_TODO, todo);
// export const updateTodo = (todo) => api.put(url.UPDATE_TODO, todo);

// // To do Project
// export const getProjects = (project) => api.get(url.GET_PROJECTS, project);
// export const addNewProject = (project) => api.create(url.ADD_NEW_TODO_PROJECT, project);

// //Job Application
// export const getJobApplicationList = () => api.get(url.GET_APPLICATION_LIST);

//API Key
export const getAPIKey = () => api.get(url.GET_API_KEY);

// Auth
export const login = (data) => api.create(url.POST_JWT_LOGIN, data);
export const refreshToken = (data) => api.get(url.POST_JWT_LOGIN);
export const register = (data) => api.createFormData(url.POST_JWT_REGISTER, data);
export const forgotPassword = (email) => api.create(url.POST_JWT_FORGOT_PASSWORD, email);
export const resetPassword = (data) => api.create(url.POST_JWT_RESET_PASSWORD, data);
export const changePassword = (data) => api.create(url.POST_JWT_CHANGE_PASSWORD, data);
export const logout = () => api.get(url.POST_JWT_LOGOUT);
export const signup = (data) => api.createFormData(url.POST_SIGNUP, data);

// CRM Customers
export const getCustomers = (searchTerm = '', page = 1, perPage = 10, sort = 'customer_id', sortOrder = 'desc', filters = {}) => api.get(url.GET_CUSTOMERS + '?search=' + searchTerm + '&page=' + page + '&per_page=' + perPage + '&sort=' + sort + '&order=' + sortOrder + '&filters=' + encodeURIComponent(JSON.stringify(filters)));
export const addNewCustomer = (customer) => api.put(url.ADD_NEW_CUSTOMER, customer);
export const updateCustomer = (customer) => api.update(url.UPDATE_CUSTOMER + customer.customer_id, customer);
export const deleteCustomer = (customerId) => api.delete(url.DELETE_CUSTOMER + customerId);
export const getCustomerById = (customerId) => api.get(url.GET_CUSTOMER_BY_ID + customerId);

// CRM Contacts
export const getContacts = () => api.get(url.GET_CONTACTS);
export const addNewContact = (contact) => api.put(url.ADD_NEW_CONTACT, contact);
export const updateContact = (contact) => api.update(url.UPDATE_CONTACT + contact.contact_id, contact);
export const deleteContact = (contactId) => api.delete(url.DELETE_CONTACT + contactId);
export const getContactById = (contactId) => api.get(url.GET_CONTACT_BY_ID + contactId);
export const sendAuthMessageToContact = (contactId) => api.get(url.SEND_AUTH_MESSAGE_TO_CONTACT + contactId);
export const getContactsByCustomerId = (customerId, searchTerm, page, perPage, sort, sortOrder) => api.get(url.GET_CONTACTS_BY_CUSTOMER_ID + customerId + '?search=' + searchTerm + '&page=' + page + '&per_page=' + perPage + '&sort=' + sort + '&order=' + sortOrder);

// CRM Site
export const getSitesByCustomerId = (customerId) => api.get(url.GET_SITES_BY_CUSTOMER_ID + customerId);
export const addNewSite = (site) => api.put(url.ADD_NEW_SITE, site);
export const updateSite = (site) => api.update(url.UPDATE_SITE + site.site_id, site);
export const deleteSite = (siteId) => api.delete(url.DELETE_SITE + siteId);
export const getSiteById = (siteId) => api.get(url.GET_SITE_BY_ID + siteId);

// CRM Transactions
export const getTransactions = (page, perPage, sort, sortOrder, searchTerm, filters) => api.get(url.GET_TRANSACTIONS + '?page=' + page + '&per_page=' + perPage + '&sort=' + sort + '&order=' + sortOrder + '&search=' + searchTerm + '&filters=' + encodeURIComponent(JSON.stringify(filters)));
export const addNewTransaction = (transaction) => api.put(url.ADD_NEW_TRANSACTION, transaction);
export const getTransactionsByCustomerId = (customerId, page, perPage, sort, sortOrder, searchTerm, filters) => api.get(url.GET_TRANSACTIONS_BY_CUSTOMER_ID + customerId + '?page=' + page + '&per_page=' + perPage + '&sort=' + sort + '&order=' + sortOrder + '&search=' + searchTerm + '&filters=' + encodeURIComponent(JSON.stringify(filters)));
export const getTransactionsByInvoiceId = (invoiceId) => api.get(url.GET_TRANSACTIONS_BY_INVOICE_ID + invoiceId);
export const deleteTransaction = (transactionId) => api.delete(url.DELETE_TRANSACTION + transactionId);
export const processJsonRPCEvent = (event) => api.create(url.PROCESS_JSON_RPC_EVENT, event);
export const getTransactionStats = () => api.get(url.GET_TRANSACTION_STATS);
export const getTransactionStatsByCustomerId = (customerId) => api.get(url.GET_TRANSACTION_STATS_BY_CUSTOMER_ID.replace('{customer_id}', customerId));

// CRM Attributes
export const getAttributes = () => api.get(url.GET_ATTRIBUTES);
export const addNewAttribute = (attribute) => api.put(url.ADD_NEW_ATTRIBUTE, attribute);
export const updateAttribute = (attribute) => api.update(url.UPDATE_ATTRIBUTE + attribute.id, attribute);
export const deleteAttribute = (attributeId) => api.delete(url.DELETE_ATTRIBUTE + attributeId);
export const getAttributeById = (attributeId) => api.get(url.GET_ATTRIBUTE_BY_ID + attributeId);
export const getAttributesByCustomerId = (customerId) => api.get(url.GET_ATTRIBUTES_BY_CUSTOMER_ID + customerId);

// CRM Services
export const getServices = (search, page, perPage, sort, sortOrder, filters) => {
  const filtersString = JSON.stringify(filters);
  return api.get(`${url.GET_SERVICES}?search=${search}&page=${page}&per_page=${perPage}&sort=${sort}&order=${sortOrder}&filters=${encodeURIComponent(filtersString)}`);
};
export const addNewService = (service) => api.put(url.ADD_NEW_SERVICE, service);
export const updateService = (service) => api.update(url.UPDATE_SERVICE.replace('{service_id}', service.service_id), service);
export const deleteService = (serviceId) => {
  // Retrieve the refresh token from sessionStorage
  const refreshToken = sessionStorage.getItem("authUser")
    ? JSON.parse(sessionStorage.getItem("authUser")).refresh_token
    : null;

  // Log the refresh token for debugging
  console.log("refreshToken", refreshToken);

  // Prepare headers
  const headers = {};
  if (refreshToken) {
    headers['Refresh-Token'] = refreshToken; // Using a custom header to avoid overwriting the Authorization header
  }

  // Make the DELETE API request with the original format
  return api.delete(url.DELETE_SERVICE.replace('{service_id}', serviceId), { headers });
};
export const getServiceById = (serviceId) => api.get(url.GET_SERVICE_BY_ID.replace('{service_id}', serviceId));
export const getSimpleServiceById = (serviceId) => api.get(url.GET_SIMPLE_SERVICE_BY_ID.replace('{service_id}', serviceId));
export const getServicesByCustomerId = (customerId, search, page, perPage, sort, sortOrder, filters) => {
  const filtersString = JSON.stringify(filters);
  return api.get(url.GET_SERVICES_BY_CUSTOMER_ID.replace('{customer_id}', customerId) + '?search=' + search + '&page=' + page + '&per_page=' + perPage + '&sort=' + sort + '&order=' + sortOrder + '&filters=' + encodeURIComponent(filtersString))
};
export const deleteActionPlanFromServiceUUID = (serviceUUID, actionPlan) => api.delete(url.DELETE_ACTION_PLAN_FROM_SERVICE_UUID.replace('{service_uuid}/{action_plan}', `${serviceUUID}/${actionPlan}`));
export const getServicesByMSISDN = (msisdn) => api.get(url.GET_SERVICES_BY_MSISDN.replace('{msisdn}', msisdn));
export const deactivateServiceByUUID = (uuid) => api.update(url.DEACTIVATE_SERVICE_BY_UUID.replace('{uuid}', uuid));

// CRM Stripe
export const getStripeObjects = (page, perPage, sort, sortOrder, searchTerm, filters) => api.get(url.GET_STRIPE_OBJECTS + '?page=' + page + '&per_page=' + perPage + '&sort=' + sort + '&order=' + sortOrder + '&search=' + searchTerm + '&filters=' + encodeURIComponent(JSON.stringify(filters)));
export const addNewStripeObject = (stripeObject) => api.put(url.ADD_NEW_STRIPE_OBJECT, stripeObject);
export const deleteStripeObject = (stripeObjectId) => api.delete(url.DELETE_STRIPE_OBJECT + stripeObjectId);
export const getStripeObjectsByCustomerId = (customerId, page, perPage, sort, sortOrder, searchTerm, filters) => api.get(url.GET_STRIPE_OBJECTS_BY_CUSTOMER_ID + customerId + '?page=' + page + '&per_page=' + perPage + '&sort=' + sort + '&order=' + sortOrder + '&search=' + searchTerm + '&filters=' + encodeURIComponent(JSON.stringify(filters)));
export const addStripePaymentForAnInvoice = (invoiceId, tokenId) => api.get(url.ADD_STRIPE_PAYMENT_FOR_AN_INVOICE.replace('{invoice_id}/{token_id}', `${invoiceId}/${tokenId}`));
export const chargeStripePayment = (tokenId, data) => api.create(url.CHARGE_STRIPE_PAYMENT, tokenId, data);
export const createPaymentIntent = (data) => api.create(url.CREATE_PAYMENT_INTENT, data);

// CRM Communications
export const getCommunicationsByCustomerId = (customerId) => api.get(url.GET_COMMUNICATIONS_BY_CUSTOMER_ID.replace('{customer_id}', customerId));
export const getCommunicationById = (communicationId) => api.get(url.GET_COMMUNICATION_BY_ID.replace('{communication_id}', communicationId));
export const addNewCommunication = (communication) => api.put(url.ADD_NEW_COMMUNICATION, communication);
export const sendInvoiceEmailToCustomer = (invoiceId) => api.get(url.SEND_INVOICE_EMAIL_TO_CUSTOMER.replace('{invoice_id}', invoiceId));

// CRM Invoices
export const getInvoices = (page, perPage, sort, sortOrder, searchTerm, filters) => api.get(url.GET_INVOICES + '?page=' + page + '&per_page=' + perPage + '&sort=' + sort + '&order=' + sortOrder + '&search=' + searchTerm + '&filters=' + encodeURIComponent(JSON.stringify(filters)));
export const addNewInvoice = (invoice) => api.put(url.ADD_NEW_INVOICE, invoice);
export const updateInvoice = (invoice) => api.update(url.UPDATE_INVOICE.replace('{invoice_id}', invoice.invoice_id), invoice);
export const deleteInvoice = (invoiceId) => api.delete(url.DELETE_INVOICE.replace('{invoice_id}', invoiceId));
export const getInvoiceById = (invoiceId) => api.get(url.GET_INVOICE_BY_ID.replace('{invoice_id}', invoiceId));
export const getInvoicesByCustomerId = (customerId, page, perPage, sort, sortOrder, searchTerm, filters) => api.get(url.GET_INVOICES_BY_CUSTOMER_ID.replace('{customer_id}', customerId) + '?page=' + page + '&per_page=' + perPage + '&sort=' + sort + '&order=' + sortOrder + '&search=' + searchTerm + '&filters=' + encodeURIComponent(JSON.stringify(filters)));
export const getInvoicePdfById = (invoiceId) => api.get(url.GET_INVOICE_PDF_BY_ID.replace('{invoice_id}', invoiceId), { responseType: 'blob' });
export const getInvoiceStats = () => api.get(url.GET_INVOICE_STATS);
export const getInvoiceStatsByCustomerId = (customerId) => api.get(url.GET_INVOICE_STATS_BY_CUSTOMER_ID.replace('{customer_id}', customerId));

// CRM Inventory
export const getAllInventoryItems = (currentPage, itemsPerPage, key, direction, searchTerm, filters) => api.get(`${url.GET_ALL_INVENTORY_ITEMS}?page=${currentPage}&per_page=${itemsPerPage}&sort=${key}&order=${direction}&search=${searchTerm}&filters=${encodeURIComponent(JSON.stringify(filters))}`);
export const createInventoryItem = (inventoryItem) => api.put(url.CREATE_INVENTORY_ITEM, inventoryItem);
export const getInventoryByCustomerId = (customerId, currentPage, itemsPerPage, key, direction, searchTerm, filters) => api.get(url.GET_INVENTORY_BY_CUSTOMER_ID.replace('{customer_id}', customerId) + '?page=' + currentPage + '&per_page=' + itemsPerPage + '&sort=' + key + '&order=' + direction + '&search=' + searchTerm + '&filters=' + encodeURIComponent(JSON.stringify(filters)));
export const deleteInventory = (inventoryId) => api.delete(url.DELETE_INVENTORY.replace('{inventory_id}', inventoryId));
export const getInventoryById = (inventoryId) => api.get(url.GET_INVENTORY_BY_ID.replace('{inventory_id}', inventoryId));
export const updateInventory = (inventoryItem) => api.update(url.UPDATE_INVENTORY.replace('{inventory_id}', inventoryItem.inventory_id), inventoryItem);
export const getInventoryByItemName = (itemName) => api.get(url.GET_INVENTORY_BY_ITEM_NAME.replace('{item_name}', itemName));
export const getInventoryByTemplateId = (templateId) => api.get(url.GET_INVENTORY_BY_TEMPLATE_ID.replace('{inventory_template_id}', templateId));
export const isInventoryItemAvailableById = (inventoryId, customerId = null) => api.get(url.IS_INVENTORY_ITEM_AVAILABLE_BY_ID.replace('{inventory_id}', inventoryId) + (customerId ? `?customer_id=${customerId}` : ''));
export const isInventoryItemAvailableBySerial1 = (serial1, customerId = null) => api.get(url.IS_INVENTORY_ITEM_AVAILABLE_BY_SERIAL1.replace('{serial1}', serial1) + (customerId ? `?customer_id=${customerId}` : ''));
export const getAvailableInventoryItemsByName = (itemName, customerId = null) => api.get(url.GET_AVAILABLE_INVENTORY_ITEMS_BY_NAME.replace('{item_name}', itemName) + (customerId ? `?customer_id=${customerId}` : ''));

// CRM Inventory Templates
export const getAllInventoryTemplates = () => api.get(url.GET_ALL_INVENTORY_TEMPLATES);
export const createInventoryTemplateItem = (templateItem) => api.put(url.CREATE_INVENTORY_TEMPLATE_ITEM, templateItem);
export const deleteInventoryTemplateById = (templateId) => api.delete(url.DELETE_INVENTORY_TEMPLATE_BY_ID.replace('{inventory_template_id}', templateId));
export const getInventoryTemplateById = (templateId) => api.get(url.GET_INVENTORY_TEMPLATE_BY_ID.replace('{inventory_template_id}', templateId));
export const updateInventoryTemplateById = (templateId, templateItem) => api.update(url.UPDATE_INVENTORY_TEMPLATE_BY_ID.replace('{inventory_template_id}', templateId), templateItem);

// CRM Inventory Totals
export const getTotalsOfInventoryItems = () => api.get(url.GET_TOTALS_OF_INVENTORY_ITEMS);

// CRM Cell Broadcasts
export const createCbcMessage = (cbcMessage) => api.put(url.CREATE_CBC_MESSAGE, cbcMessage);
export const getAllCbcItems = () => api.get(url.GET_ALL_CBC_ITEMS);
export const deleteCbcMessage = (cbcMessageId) => api.delete(url.DELETE_CBC_MESSAGE.replace('{cbc_message_id}', cbcMessageId));

// OAM
export const searchDatabase = (searchQuery) => api.get(url.SEARCH_DATABASE.replace('{searchQuery}', searchQuery));
export const getDatabaseStats = () => api.get(url.GET_DATABASE_STATS);
export const getUsage = () => api.get(url.GET_USAGE);
export const findCustomerByImsi = (imsi) => api.get(url.FIND_CUSTOMER_BY_IMSI.replace('{imsi}', imsi));
export const findServiceByImsi = (imsi) => api.get(url.FIND_SERVICE_BY_IMSI.replace('{imsi}', imsi));
export const listImsiByCustomerId = (customerId) => api.get(url.LIST_IMSI_BY_CUSTOMER_ID.replace('{customer_id}', customerId));
export const removeActionPlan = (actionPlanId) => api.delete(url.REMOVE_ACTION_PLAN.replace('{actionPlanId}', actionPlanId));

// CRM Products & Plans
export const createNewProduct = (product) => api.put(url.CREATE_NEW_PRODUCT, product);
export const getAllProducts = (filters) => api.get(url.GET_ALL_PRODUCTS + '?filters=' + encodeURIComponent(JSON.stringify(filters)));
export const updateProduct = (productId, product) => api.update(url.UPDATE_PRODUCT.replace('{product_id}', productId), product);
export const deleteProduct = (productId) => api.delete(url.DELETE_PRODUCT.replace('{product_id}', productId));
export const getProductById = (productId) => api.get(url.GET_PRODUCT_BY_ID.replace('{product_id}', productId));

// CRM Provisioning
// export const createNewProvisioningJob = (provisioningJob) => api.put(url.CREATE_NEW_PROVISIONING_JOB, provisioningJob);
export const createNewProvisioningJob = (provisioningJob) => {
  // Retrieve the refresh_token from sessionStorage
  const refreshToken = sessionStorage.getItem("authUser") ? JSON.parse(sessionStorage.getItem("authUser")).refresh_token : null;

  // Prepare headers
  const headers = {};
  if (refreshToken) {
    headers['Refresh-Token'] = refreshToken; // Using a custom header to avoid overwriting the Authorization header
  }

  // Make the API call with the additional header
  return api.putWithAdditionalHeaders(url.CREATE_NEW_PROVISIONING_JOB, provisioningJob, headers );
};
export const updateProvisioningRecord = (provisioningRecord) => api.update(url.UPDATE_PROVISIONING_RECORD, provisioningRecord);
export const createNewProvisioningRecord = (provisioningRecord) => api.create(url.CREATE_NEW_PROVISIONING_RECORD, provisioningRecord);
export const getAllProvisioningJobs = (page, perPage, sort, sortOrder, search, filters) => api.get(url.GET_ALL_PROVISIONING_JOBS + '?page=' + page + '&per_page=' + perPage + '&sort=' + sort + '&order=' + sortOrder + '&search=' + search + '&filters=' + encodeURIComponent(JSON.stringify(filters)));
export const getAvailablePlaybooks = () => api.get(url.GET_AVAILABLE_PLAYBOOKS);
export const getProvisioningStatus = (provisioningId) => api.get(url.GET_PROVISIONING_STATUS.replace('{provisioning_id}', provisioningId));
export const createSimpleProvisionJob = (serviceId, productId) => api.get(url.CREATE_SIMPLE_PROVISION_JOB.replace('{service_id}', serviceId).replace('{product_id}', productId));

// CRM Activities
export const createActivity = (activity) => api.put(url.CREATE_ACTIVITY, activity);
export const deleteActivity = (activityId) => api.delete(url.DELETE_ACTIVITY.replace('{activity_id}', activityId));
export const getActivityById = (activityId) => api.get(url.GET_ACTIVITY_BY_ID.replace('{activity_id}', activityId));
export const updateActivity = (activity) => api.patch(url.UPDATE_ACTIVITY.replace('{activity_id}', activity.activity_id), activity);
export const getActivitiesByCustomerId = (customerId, page, perPage, sort, sortOrder, search, filters) => api.get(url.GET_ACTIVITIES_BY_CUSTOMER_ID.replace('{customer_id_to_find}', customerId) + '?page=' + page + '&per_page=' + perPage + '&sort=' + sort + '&order=' + sortOrder + '&search=' + search + '&filters=' + encodeURIComponent(JSON.stringify(filters)));

// CRM Users
export const getUsers = (searchTerm, page, perPage, sort, sortOrder, filters) => api.get(url.GET_ALL_USERS + '?search=' + searchTerm + '&page=' + page + '&per_page=' + perPage + '&sort=' + sort + '&order=' + sortOrder + '&filters=' + encodeURIComponent(JSON.stringify(filters)));
export const addUser = (user) => api.createFormData(url.CREATE_NEW_USER, user);
export const updateUser = (user) => {
  const userId = user.get('id');
  return api.updatePutFormData(url.UPDATE_USER.replace('{user_id}', userId), user);
};
export const deleteUser = (userId) => api.delete(url.DELETE_USER.replace('{user_id}', userId));
export const getUserById = (userId) => api.get(url.GET_USER_BY_ID.replace('{user_id}', userId));
export const sendUserWelcomeEmail = (userId) => api.get(url.SEND_USER_WELCOME_EMAIL.replace('{user_id}', userId));

// Roles
export const getRoles = () => api.get(url.GET_ALL_ROLES);
export const addRole = (role) => api.put(url.CREATE_NEW_ROLE, role);
export const updateRole = (role) => api.update(url.UPDATE_ROLE.replace('{role_id}', role.id), role);
export const deleteRole = (roleId) => api.delete(url.DELETE_ROLE.replace('{role_id}', roleId));
export const getRoleById = (roleId) => api.get(url.GET_ROLE_BY_ID.replace('{role_id}', roleId));

// Permissions
export const getPermissions = () => api.get(url.GET_ALL_PERMISSIONS);
export const addPermission = (permission) => api.put(url.CREATE_NEW_PERMISSION, permission);
export const updatePermission = (permission) => api.update(url.UPDATE_PERMISSION.replace('{permission_id}', permission.id), permission);
export const deletePermission = (permissionId) => api.delete(url.DELETE_PERMISSION.replace('{permission_id}', permissionId));
export const getPermissionById = (permissionId) => api.get(url.GET_PERMISSION_BY_ID.replace('{permission_id}', permissionId));

// Utilities
export const searchEverything = (query, page = 1, per_page = 10) => api.get(url.SEARCH_EVERYTHING + '?search=' + query + '&page=' + page + '&per_page=' + per_page);