import React, { useState } from 'react';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

const WebAppsDropdown = () => {
    const [isWebAppDropdown, setIsWebAppDropdown] = useState(false);
    const toggleWebAppDropdown = () => {
        setIsWebAppDropdown(!isWebAppDropdown);
    };

    // Function to dynamically fetch all web apps from environment variables
    const getWebApps = () => {
        const apps = [];
        let index = 1;
        while (process.env[`REACT_APP_WEB_APP_${index}_NAME`]) {
            apps.push({
                name: process.env[`REACT_APP_WEB_APP_${index}_NAME`],
                url: process.env[`REACT_APP_WEB_APP_${index}_URL`],
                iconPath: process.env[`REACT_APP_WEB_APP_${index}_ICON_PATH`],
            });
            index++;
        }
        return apps;
    };

    const apps = getWebApps();

    return (
        <React.Fragment>
            <Dropdown isOpen={isWebAppDropdown} toggle={toggleWebAppDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle tag="button" type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-category-alt fs-22'></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg p-0 dropdown-menu-end">
                    <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                        <Row className="align-items-center">
                            <Col>
                                <h6 className="m-0 fw-semibold fs-15"> Web Apps </h6>
                            </Col>
                        </Row>
                    </div>

                    <div className="p-2">
                        <div className="row g-0">
                            {apps.slice(0, 3).map((app, index) => (
                                <Col key={index}>
                                    <a className="dropdown-icon-item" href={app.url} target="_blank" rel="noopener noreferrer">
                                        <img src={app.iconPath} alt={app.name} />
                                        <span>{app.name}</span>
                                    </a>
                                </Col>
                            ))}
                        </div>
                        <div className="row g-0">
                            {apps.slice(3).map((app, index) => (
                                <Col key={index}>
                                    <a className="dropdown-icon-item" href={app.url} target="_blank" rel="noopener noreferrer">
                                        <img src={app.iconPath} alt={app.name} />
                                        <span>{app.name}</span>
                                    </a>
                                </Col>
                            ))}
                        </div>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default WebAppsDropdown;
